
  import userController from '/home/builduser/work/acf1b19a5d9e1adb/packages/pro-gallery-tpa/src/components/WixProGallery/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://d2da005893e64a638a4aa6cb7f0dd60c@sentry.wixpress.com/3939',
    id: 'd2da005893e64a638a4aa6cb7f0dd60c',
    projectName: 'pro-gallery-tpa',
    teamName: 'photography',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/acf1b19a5d9e1adb/packages/pro-gallery-tpa/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var biConfig = null;

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/acf1b19a5d9e1adb/packages/pro-gallery-tpa/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Wix Pro Gallery",
    componentName: "WixProGallery",
    appDefinitionId: "14271d6f-ba62-d045-549b-ab972ae1f70e",
    componentId: "142bb34d-3439-576a-7118-683e690a1e0d",
    projectName: "pro-gallery-tpa",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/acf1b19a5d9e1adb/packages/pro-gallery-tpa/src/components/WixProGallery/controller.ts",
  };

  export const controller = _controller
  export default controller;
